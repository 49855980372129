<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import loadSections from "@/mixins/loadSections";
import firebaseDeliveryBindOrder from "@/mixins/firebase/bindDeliveryOrder";

export default {
  name: "MenuOrderDetailsPaymentOtherTip",

  metaInfo: { title: "Agregar propina" },

  extends: View,

  mixins: [
    firebaseDeliveryBindOrder,
    loadSections([
      "layout-header-menu-with-return-button-and-title",
      "public-menu-order-details-payment-other-tip",
    ]),
  ],
};
</script>
